import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import IndexPage from './pages/IndexPage';
import CoursesPage from './pages/CoursesPage';
import HowToPayPage from './pages/HowToPayPage';
import UnknownPage from './pages/UnknownPage';


import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={IndexPage} />
        <Route path="/courses" component={CoursesPage} />
        <Route path="/how-to-pay" component={HowToPayPage} />
        <Route path="*" component={UnknownPage} status={404}/>
      </Switch>
    </Router>
  );
}

export default App;
