import { NavLink } from 'react-router-dom';


function HowToPaySection() {
  return (
    <section id="how-to-pay" className="about">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Как заплатить</h2>
          <p>Инструкция по оплате курсов</p>
        </div>

        <div className="row">
          <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
            <img src="assets/img/pay-step-1.png" className="img-fluid" alt="How to pay - step 1" />
          </div>

          <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
            <h3>Шаг 1: Вводим сумму и назначение платежа</h3>
            <p className="font-italic">
              Стоимость занятий можно посмотреть на странице <NavLink to="/courses">"Курс на выбор"</NavLink> </p>
            <ul>
              <li><i className="icofont-check-circled"></i> Переход на персональную платежную систему BillPocket <br/><i className="icofont-arrow-right"></i>
                <a target="_blank" href="https://pay.billpocket.com/sergiizobin">https://pay.billpocket.com/sergiizobin</a>
              </li>
              <li><i className="icofont-check-circled"></i> Введите сумму платежа в поле "Monto a enviar" (Сумма для отправки). 
              Сумма в мексиканских песо. Курс около 20 MXN за доллар. Детальнее курс можно посмотреть, а также вычислить нужную сумму здесь: <br/>
                <i className="icofont-arrow-right"></i><a target="_blank" href="https://freecurrencyrates.com/ru/convert-USD-MXN">https://freecurrencyrates.com/ru/convert-USD-MXN</a>
              </li>
              <li><i className="icofont-check-circled"></i> Введите назначение платежа в поле "Mensaje". Желательно написать вашу фамилию и количество уроков, которые вы оплачиваете. Фамилию, пожалуйста, напишите латинским буквами.</li>
              <li><i className="icofont-check-circled"></i> Для продолженния нажмите на кнопку <b>"SIGUIENTE"</b> (далее).</li>
            </ul>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-6 pt-4 pt-lg-0 order-1 order-lg-1 content">
            <h3>Шаг 2: Введите данные своей карточки</h3>
            <p className="font-italic"> </p>
            <ul>
              <li><i className="icofont-check-circled"></i> В поле <b>Nombre</b> введите свое имя, как написано ка карточке латинскими буквами</li>
              <li><i className="icofont-check-circled"></i> В поле <b>Apellidos</b> введите свою фамилию, как написано ка карточке латинскими буквами</li>
              <li><i className="icofont-check-circled"></i> В поле <b>Numero de tarjeta</b> введите номер своей карточки</li>
              <li><i className="icofont-check-circled"></i> В поле <b>Vigencia</b> введите срок окончания действия карты (expired date)</li>
              <li><i className="icofont-check-circled"></i> В поле <b>Codigo CVV</b> введите 3-значный код (security code)</li>
              <li><i className="icofont-check-circled"></i> Поле <b>Planes de pago</b> (рассрочка платежа) можно не менять;</li>
              <li><i className="icofont-check-circled"></i> После завершения ввода нажмите на кнопку <b>"REALIZAR PAGO"</b> (выполнить платеж)</li>
            </ul>
          </div>
          <div className="col-lg-6 order-2 order-lg-2" data-aos="fade-right" data-aos-delay="100">
            <img src="assets/img/pay-step-2.png" className="img-fluid" alt="How to pay - step 2" />
          </div>
        </div>


      </div>

    </section>
  );
}

export default HowToPaySection;
