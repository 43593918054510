import Header from '../components/Header';
import Footer from '../components/Footer';
import Breadcrumbs from '../components/Breadcrumbs';
import HowToPaySection from '../components/HowToPaySection';


function InfoPage() {
  return (
    <div className="App">
          <Header />
          <main id="main" data-aos="fade-in">
            <Breadcrumbs />
            <HowToPaySection />
          </main>
          <Footer />
     </div>
   );
}

export default InfoPage;
